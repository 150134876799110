.intern-component {
  text-align: center;
}

.list-oL {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: var(--body-text);
  width: 80%;
  margin: 0 auto;
  border-bottom: 1px solid darkgray;
}

.list-oL:first-of-type {
  border-bottom: 1px solid rgb(100, 100, 100);
}

.list-oL:last-of-type {
  border-bottom: none;
}

.names {
  margin: 5px;
}

.names > p {
  margin-right: 3.95px;
}

.admin-buttons {
  display: grid;
  justify-self: center;
}

.add-new {
  margin: 5px;
  padding: 5px 10px;
  font-size: 0.9rem;
  font-family: var(--body-text);
  border: 1px solid green;
  color: green;
  border-radius: 5px;
}

.add-new:hover {
  color: antiquewhite;
  border: 1px solid green;
  background-color: green;
  cursor: pointer;
}

.addBtnBox {
  display: grid;
  justify-self: center;
  display: flex;
}

.close-btn {
  margin: 5px;
  padding: 5px 10px;
  font-size: 0.9rem;
  font-family: var(--body-text);
  border: 1px solid red;
  color: red;
  border-radius: 5px;
  width: 100px;
}

.close-btn:hover {
  color: antiquewhite;
  border: 1px solid red;
  background-color: red;
  cursor: pointer;
}

.add-btn {
  width: 100px;
}

.sortBy {
  display: flex;
}

.sortBy > button {
  margin: 0px 5px 5px 5px;
  padding: 3px;
  border: 1px solid darkgray;
  border-radius: 5px;
}

.sortBy > button:hover {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-btn {
  margin-left: 3px;
  font-size: 1rem;
  border: none;
}

#smallerInput {
  height: 20px;
  width: 120px;
  font-size: 0.8rem;
}

/* Das Feld um neue Burschen/Balbinen hinzuzufügen */

.addfield {
  display: grid;
  justify-self: center;
  margin: 5px 0px;
  border: 1px solid darkgray;
  border-radius: 5px;
  padding: 10px;
}
