.countdown {
  text-align: center;
  font-family: var(--body-text);
}

@media (max-width: 375px) {
  .countdown {
    font-size: 1.4rem;
  }
}
@media (min-width: 376px) and (max-width: 414px) {
  .countdown {
    font-size: 1.8rem;
  }
}
