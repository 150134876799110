button:focus,
input:focus,
textarea:focus {
  outline: 0;
}

.contact {
  margin-top: 25px;
  display: grid;
}

.balbach-contact {
  display: flex;
  flex-direction: column;
  margin: 1px 35px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.contact-info {
  font-family: var(--body-text);
  font-weight: 200;
  width: 70%;
  margin: -10px auto 5px auto;
  font-size: 0.9rem;
  text-align: center;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
  display: flex;
  align-items: flex-start;
  font-size: 0.9rem;
  border: 1px solid rgb(175, 171, 171);
  height: 33px;
  margin: 5px 10px;
  width: 275px;
  border-radius: 5px;
  padding-left: 10px;
}

textarea {
  font-size: 0.9rem;
  box-sizing: border-box;
  margin: 0.6rem;
  padding: 10px;
  padding-top: 0.3rem;
  height: 250px;
  width: 275px;
  border: 0.6px solid rgba(219, 219, 219, 0.616);
  border-radius: 5px;
  resize: none;
  border: 1px solid rgb(175, 171, 171);
}

.contact-submit {
  display: grid;
  align-self: center;
  width: 100px;
  color: rgb(109, 182, 26);
  border: 1px solid rgb(109, 182, 26);
  padding: 5px;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-submit:hover {
  background-color: rgb(117, 199, 24);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(117, 199, 24);
}

.errorMsg,
.okMsg {
  display: grid;
  align-self: center;
  margin: 10px;
  color: red;
  font-family: var(--body-text);
}

.okMsg {
  color: green;
  margin: 15px 0px 0px 0px;
}

@media (max-width: 374px) {
  .balbach-contact {
    margin: 1px 5px;
  }
  .textarea {
    width: 220px;
  }
}

@media (min-width: 375px) and (max-width: 413px) {
  .inputs {
    display: grid;
    align-self: center;
  }
  .textarea {
    width: 275px;
  }
  .balbach-contact {
    margin: 1px 5px;
  }
}

@media (min-width: 450px) and (max-width: 766px) {
  .inputs {
    display: grid;
    align-self: center;
  }
}

@media (min-width: 768px) {
  .balbach-contact {
    grid-template-columns: 2;
  }

  .inputs {
    width: 275px;
    grid-column: 1/2;
    justify-content: center;
    justify-self: flex-end;
    padding: 5px 0px 5px 0px;
    padding-top: 0.3rem;
  }

  textarea {
    grid-row: 1;
    grid-column: 2/2;
  }

  .contact-submit,
  .errorMsg,
  .okMsg {
    margin-top: 13px;
    grid-column: 1/3;
  }
}

@media (min-width: 768px) {
  textarea {
    justify-self: flex-start;
    margin-left: 50px;
  }
}
