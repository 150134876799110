.geschichte {
  width: 50%;
  margin: auto;
}

.geschichte > h1 {
  margin: 10px 0 10px 0;
  font-family: var(--welcome-text);
  text-align: center;
}

.geschichte > p {
  font-family: var(--body-text);
}

@media (max-width: 639px) {
  .geschichte {
    width: 90%;
  }
  .geschichte > p {
    margin: 5px 15px;
  }
}

@media (min-width: 640px) and (max-width: 849px) {
  .geschichte {
    width: 80%;
  }
}

@media (min-width: 850px) and (max-width: 1150px) {
  .geschichte {
    width: 60%;
  }
}
