@font-face {
  font-family: "Assistant";
  src: local("Assistant-Regular"),
    url(./assets/fonts/Assistant/Assistant-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Amatic SC";
  src: local("AmaticSC-Regular"),
    url(./assets/fonts/Amatic_SC/AmaticSC-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Shadows Into Light Two";
  src: local("ShadowsIntoLightTwo-Regular"),
    url(./assets/fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway-Regular"),
    url(./assets/fonts/Raleway/Raleway-Regular.ttf) format("truetype");
}

:root {
  --nav-text: "Amatic SC", cursive;
  --welcome-text: "Shadows Into Light Two", cursive;
  --body-text: "Assistant", sans-serif;
}

* {
  background-color: rgb(218, 221, 218);
  padding: 0;
  margin: 0;
}

button:active,
button:focus {
  outline: none;
}
