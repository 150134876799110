.history {
  width: 99%;
}

.history-heading {
  font-size: 2.5rem;
}

.subheading-history {
  text-align: center;
  font-family: var(--body-text);
}

.history-list {
  display: flex;
  overflow-x: scroll;
}

.history-item {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  min-height: 1000px;
  max-height: 2000px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 15px;
  margin-bottom: 50px;
  padding: 15px 5px;
  border-radius: 5px;
}

.history-heading {
  font-family: var(--welcome-text);
  text-align: center;
}

.schleife {
  align-self: center;
  margin-top: 8px;
  height: 280px;
}

.history-position {
  font-family: var(--welcome-text);
  margin: 8px 0px 10px 15px;
  font-size: 1.5rem;
}

.history-names {
  font-family: var(--body-text);
  margin: 8px 0px 10px 15px;
}
