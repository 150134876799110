.login {
  height: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login > h2 {
  font-family: var(--welcome-text);
  font-size: 2rem;
}

.login > p {
  font-family: var(--body-text);
  font-size: 1.3rem;
  margin: 10px 0px;
}

.login-submit {
  padding: 5px;
  font-family: var(--body-text);
  margin-top: 5px;
  font-size: 1.1rem;
  border-radius: 5px;
  border: 1px solid darkgray;
}

.login-submit:hover {
  background-color: darkgray;
  color: white;
  border: 1px solid darkgray;
}

.login-submit:focus,
.login-submit:active {
  color: white;
  outline: none;
  border: 1px solid darkgray;
}

#vorst-login {
  margin-top: 45px;
}
