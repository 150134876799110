nav {
  display: grid;
  grid-template-columns: 1fr 150px 1fr;
  align-items: center;
  justify-items: center;
  padding: 10px 10px 0px 0px;
  width: 100%;
}

.menu-toggle {
  display: none;
}

.head-logo {
  grid-column: 2;
  grid-row: 1;
  width: 80px;
  margin: 0px 15px;
}

.nav-section:first-of-type {
  grid-column: 1;
  grid-row: 1;
  justify-self: flex-end;
}

.nav-sections {
  display: grid;
  grid-column: 3;
  justify-self: flex-start;
}

.heading-links {
  text-decoration: none;
  margin: 8px;
  font-size: 1.6rem;
  color: rgb(46, 45, 45);
  border: 1px solid rgba(0, 0, 0, 0);
  font-family: "Amatic SC", cursive;
}

.heading-links:hover {
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.logouter:hover {
  cursor: pointer;
}

@media (max-width: 630px) {
  nav {
    display: grid;
    padding: 10px 0px 10px 0px;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1;
  }

  .head-logo {
    width: 50px;
  }

  .head-logo {
    grid-column: 1;
    justify-self: flex-start;
  }

  .nav-section,
  .nav-sections {
    display: none;
  }

  .linklist1,
  .linklist2 {
    display: flex;
    flex-direction: column;
  }

  .countdown {
    display: grid;
    grid-column: 2;
    margin-left: 5px;
  }

  .menu-toggle {
    display: grid;
    border: 1px solid rgba(0, 0, 0, 0);
    justify-self: flex-end;
    margin-right: 10px;
  }

  .menu-toggle:active {
    transform: rotate(170deg);
  }
}

@media (min-width: 375px) and (max-width: 414px) {
  .head-logo {
    width: 65px;
  }
}

@media (min-width: 630px) {
  .countdown {
    display: grid;
    grid-row: 2;
    margin-top: 3px;
    grid-column: 2;
    text-align: center;
  }
}
