.news {
  overflow: hidden;
}

.news-list {
  height: 100%;
  width: 100%;
  padding-left: 3%;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  overflow: scroll;
}

.hide-it {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.single-news {
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  margin: 15px;
  box-shadow: 7px 3px 10px rgba(0, 0, 0, 0.312),
    -7px -3px 10px rgba(0, 0, 0, 0.312);
}

.news-image {
  object-fit: contain;
  background-color: white;
  width: 280px;
  height: 200px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.text-sec {
  margin-top: -5px;
  width: 280px;
  max-height: 200px;
  overflow: scroll;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.lyrik-sec {
  margin-top: -5px;
  width: 280px;
  max-height: 320px;
  overflow: scroll;
  font-size: 0.7rem;
  background-color: white;
  border-radius: 10px;
  white-space: pre-wrap;
}

.news-title,
.news-text {
  background-color: white;
  white-space: pre-wrap;
}

.news-title {
  font-family: var(--welcome-text);
  font-size: 1.4rem;
  text-align: center;
  padding: 15px 10px 5px 10px;
  word-spacing: -3.5px;
}

.news-text {
  padding: 5px 20px 15px 20px;
  font-family: "Raleway", sans-serif;
}

.news-text.poesie {
  min-height: 280px;
}

.news-text:last-of-type:after {
  content: "";
  display: block;
  height: 25px;
  width: 100%;
}

@media (max-width: 415px) {
  .news-title {
    font-size: 1.2rem;
  }
  .lyrik-sec {
    font-size: 0.7rem;
  }
}

@media (min-width: 700px) and (max-width: 1023px) {
  .lyrik-sec {
    width: 350px;
    max-height: 340px;
    font-size: 0.9rem;
  }
}

@media (min-width: 1024px) {
  .lyrik-title {
    font-size: 2rem;
  }
  .lyrik-sec {
    width: 420px;
    max-height: 340px;
    font-size: 1.1rem;
  }
}
