.amtstraeger-19 > h1 {
  text-align: center;
  font-family: var(--welcome-text);
  margin: 10px 0px 10px 0px;
}

.amtstraeger-19 > h2 {
  width: 80%;
  margin: 10px auto 15px auto;
  font-family: var(--welcome-text);
}

.amtstraeger-row {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.single-amtstraeger {
  margin: 10px;
  display: grid;
  justify-content: center;
}

.single-amtstraeger > img {
  width: 240px;
  border-radius: 5%;
}

.position,
.amtstraeger-name {
  text-align: center;
}

.position {
  margin: -5px 0px 5px 0px;
  font-family: var(--body-text);
}

.amtstraeger-name {
  margin-top: 5px;
  font-size: 1.4rem;
  font-family: var(--welcome-text);
}

@media (max-width: 600px) {
  .amtstraeger-19 > h2 {
    text-align: center;
  }
  .amtstraeger-row {
    width: 85%;
    grid-template-columns: 70%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
  }
  .amtstraeger-row:last-of-type {
    border-bottom: none;
  }
  .single-amtstraeger {
    display: grid;
    justify-content: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .single-amtstraeger > img {
    width: 250px;
    border-radius: 5%;
  }
}

@media (min-width: 601px) and (max-width: 709px) {
  .single-amtstraeger {
    display: grid;
    justify-content: center;
  }
  .amtstraeger-row {
    width: 100%;
    grid-template-columns: 55% 30%;
  }
}

@media (min-width: 710px) and (max-width: 949px) {
  .amtstraeger-row {
    grid-template-columns: 50% 35%;
  }
}

@media (min-width: 950px) and (max-width: 1165px) {
  .amtstraeger-row {
    grid-template-columns: 40% 25%;
  }
}
