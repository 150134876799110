.footer {
  font-family: var(--nav-text);
  text-align: center;
  width: 80%;
  margin: auto;
  border-top: 1px solid rgba(163, 159, 159, 0.705);
  height: 30px;
  margin-top: 2rem;
  padding-top: 0.5rem;
}

.footer > a {
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  text-decoration: none;
  color: black;
}

.footer > a:first-of-type {
  border-right: 1px solid var(--ras-gold);
}

.footer > a:hover {
  color: var(--ras-blue);
}

.bottenhorn {
  font-family: var(--nav-text);
  text-align: center;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.heart_icon {
  padding: 2px 2px 0px 2px;
}
