.intern {
  display: grid;
}

.intern > h1 {
  text-align: center;
  margin-top: 15px;
  font-family: var(--welcome-text);
}

.switch-buttons {
  display: grid;
  justify-self: center;
  margin-top: 10px;
  display: flex;
}

.switch-button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 1.1rem;
  font-family: var(--body-text);
  border: 1px solid darkgray;
  border-radius: 5px;
}

.switch-button:hover {
  cursor: pointer;
  background-color: darkgray;
}

.iban {
  font-family: var(--body-text);
  text-align: center;
  padding: 5px;
  margin-bottom: 8px;
  color: rgb(34, 145, 12);
}
