.welcome-header,
.news-heading {
  text-align: center;
  font-family: var(--welcome-text);
}

.welcome-header {
  font-size: 4rem;
}

.news-heading {
  font-size: 2.4rem;
  margin: 30px 0px 25px 0px;
}

.cut-line {
  height: 1px;
  width: 80%;
  margin: auto;
  background-color: rgba(163, 159, 159, 0.705);
}

.welcome-subheading {
  text-align: center;
  font-size: 1.9rem;
  font-family: var(--nav-text);
  margin-bottom: 25px;
}

@media (max-width: 321px) {
  .welcome-header {
    margin-top: 5px;
    font-size: 2rem;
    text-align: center;
    font-family: var(--welcome-text);
  }

  .welcome-subheading {
    text-align: center;
    font-size: 1.3rem;
    font-family: var(--nav-text);
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .welcome-header {
    font-size: 2rem;
  }

  .welcome-subheading {
    font-size: 1.6rem;
  }
}

@media (min-width: 376px) and (max-width: 630px) {
  .welcome-header {
    font-size: 2.6rem;
  }
  .welcome-subheading {
    font-size: 2rem;
  }
}
