.rechtstexte {
  width: 90%;
  margin: auto;
}

.rechtstexte > h1,
.rechtstexte > h2,
.rechtstexte > h3 {
  font-family: var(--welcome-text);
  font-size: 1.4rem;
  margin: 8px 0px;
}

.rechtstexte > p {
  font-family: var(--body-text);
  font-size: 1rem;
}
