.hidden-menu,
.toggled-menu {
  text-align: center;
  display: grid;
  grid-column: 2;
  overflow: hidden;
  max-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.5s ease;
}

.hidden-menu {
  max-height: 0px;
}

@media (min-width: 375px) and (max-width: 414px) {
  .hello {
    width: 55px;
    height: 45px;
  }
}
